import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

import Nouislider from 'nouislider-react'
import 'nouislider/distribute/nouislider.min.css'

const prices = {
  monthly: {
    0: 46,
    2: 46,
    3: 47,
    4: 48,
    5: 49,
    6: 50,
    7: 51.5,
    8: 53,
    9: 54.5,
    10: 60,
    15: 65,
    20: 72.5,
    25: 82.5,
    30: 95,
    35: 105,
    40: 115,
    45: 125,
    50: 135,
    51: 160,
    76: 185,
    101: 275,
    126: 300,
    151: 325,
    175: 325,
    176: 350,
    201: 375,
    226: 400,
    251: 450,
    301: 500,
    351: 550,
    401: 600,
    451: 650,
    501: 800,
    601: 1000,
    701: 1200,
    801: 1400,
    901: 1600,
    1000: 1600,
    1100: 1900,
  },
  quarterly: {
    0: 138,
    2: 138,
    3: 141,
    4: 144,
    5: 147,
    6: 150,
    7: 154.5,
    8: 159,
    9: 163.5,
    10: 180,
    15: 195,
    20: 217.5,
    25: 247.5,
    30: 285,
    35: 315,
    40: 345,
    45: 375,
    50: 405,
    51: 480,
    76: 555,
    101: 825,
    126: 900,
    151: 975,
    176: 1050,
    201: 1125,
    226: 1200,
    251: 1350,
    301: 1500,
    351: 1650,
    401: 1800,
    451: 1950,
    501: 2400,
    601: 3000,
    701: 3600,
    801: 4200,
    901: 4800,
    1000: 4800,
    1100: 5700,
  },
  annually: {
    0: 460,
    2: 460,
    3: 470,
    4: 480,
    5: 490,
    6: 500,
    7: 515,
    8: 530,
    9: 545,
    10: 600,
    15: 650,
    20: 725,
    25: 825,
    30: 950,
    35: 1050,
    40: 1150,
    45: 1250,
    50: 1350,
    51: 1600,
    76: 1850,
    101: 2750,
    126: 3000,
    151: 3250,
    176: 3500,
    201: 3750,
    226: 4000,
    251: 4500,
    301: 5000,
    351: 5500,
    401: 6000,
    451: 6500,
    501: 8000,
    601: 10000,
    701: 12000,
    801: 14000,
    901: 16000,
    1000: 16000,
    1100: 19000,
  },
}

const formatPrice = (price) => {
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  }).format(price)
}

const range = {
  min: [2, 1],
  '30%': [10, 5],
  '50%': [50, 25],
  '70%': [250, 50],
  '90%': [500, 100],
  max: 1100,
}

const PriceRange = () => {
  const [activePlan, setActivePlan] = useState('annually')
  const [turnover, setTurnover] = useState('0')
  const [priceTurnoverLabel, setPriceTurnoverLabel] = useState(
    'Less than £2 million'
  )
  const [pricePerMonthLabel, setPricePerMonthLabel] = useState('£0')
  const [timeFrameLabel, setTimeFrameLabel] = useState('annum')

  useEffect(() => {
    let price
    // TODO: Rework confusing slider values from old site
    if (turnover == 2) {
      setPriceTurnoverLabel('Less than £2 million')
      price = prices[activePlan][0]
    } else if (turnover > 1000) {
      setPriceTurnoverLabel('Greater than £1 billion')
      price = prices[activePlan][turnover]
    } else if (turnover >= 1000) {
      setPriceTurnoverLabel('Less than £1 billion')
      price = prices[activePlan][turnover]
    } else {
      let match = '0'

      for (let key in prices[activePlan]) {
        if (turnover >= parseInt(key)) {
          match = key
        }
        if (turnover < parseInt(key)) break
      }

      setPriceTurnoverLabel(`Less than £${turnover} million`)
      price = prices[activePlan][match]
    }

    setPricePerMonthLabel(formatPrice(price))

    switch (activePlan) {
      case 'monthly':
        setTimeFrameLabel('month')
        break
      case 'quarterly':
        setTimeFrameLabel('quarter')
        break
      case 'annually':
        setTimeFrameLabel('annum')
        break
    }
  }, [activePlan, turnover]) // Array ensures that effect is only run on state change
  return (
    <section
      id="pricing"
      className="c-section c-section--forPricing c-section--forPricingRange"
    >
      <div className="c-section__outer container">
        <div className="c-section__tag">Our pricing</div>
        <div className="c-section__inner">
          <div className="row">
            <div className="col-12 col-lg-4  col-xl-5 text-left">
              <div className="c-pricingRangeIntro pr-xl-5">
                <h2 className="pr-xl-5">Payment options to suit you</h2>
                <p className="mt-3 mb-0 pr-xl-5">
                  The cost of your Erudus subscription is calculated by the
                  annual turnover of your business. Pay monthly, quarterly or
                  annually by direct debit, card or bacs.
                </p>
                <p className="mt-3 mb-0 pr-xl-5">
                  Your subscription allows you to upload data and images for an
                  unlimited number of products to the Erudus platform.
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-8 col-xl-7">
              <ul className="c-pricePlanToggle mb-4">
                <li
                  className={`c-pricePlanToggle__link ${
                    activePlan === 'monthly' ? 'is-active' : ''
                  }`}
                  onClick={() => setActivePlan('monthly')}
                >
                  Monthly
                </li>
                <li
                  className={`c-pricePlanToggle__link ${
                    activePlan === 'quarterly' ? 'is-active' : ''
                  }`}
                  onClick={() => setActivePlan('quarterly')}
                >
                  Quarterly
                </li>
                <li
                  className={`c-pricePlanToggle__link ${
                    activePlan === 'annually' ? 'is-active' : ''
                  }`}
                  onClick={() => setActivePlan('annually')}
                >
                  Annually
                </li>
              </ul>
              <div className="c-priceRange">
                <div className="c-priceRangeHeader">
                  <div className="c-priceRangeTurnover">
                    <h5 className="mb-0">Annual turnover:</h5>
                    <h5 className="mb-0">{priceTurnoverLabel}</h5>
                  </div>
                  <p className="c-priceRangeLabel mb-0 text-right">
                    <span className="d-block">{pricePerMonthLabel}</span>
                    {`Per ${timeFrameLabel}`}
                  </p>
                </div>
                <div className="c-priceRangeSliderWrapper">
                  <Nouislider
                    range={range}
                    start={1}
                    className="c-priceRangeSlider"
                    onUpdate={(value) => {
                      setTurnover(parseInt(value))
                    }}
                  />
                </div>
                <div className="c-priceRangeFooter">
                  <p className="c-priceRangeLabel">
                    <span>{pricePerMonthLabel}</span> Per {timeFrameLabel}
                  </p>
                  <Link
                    to="/manufacturers/sign-up"
                    state={{ activePlan }}
                    className="c-button c-button--primary"
                  >
                    Sign up
                  </Link>
                </div>
              </div>
              <p className="c-priceRangeLinks">
                This is subscription pricing for <strong>Manufacturers</strong>.
                See <Link to="/wholesalers#pricing">Wholesaler</Link>,{' '}
                <Link to="/caterers#pricing">Caterer</Link> or{' '}
                <Link to="/retailers#pricing">Retailer</Link> pricing.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PriceRange

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import cn from 'classnames'
import _orderBy from 'lodash/orderBy'
import _take from 'lodash/take'

import Page from '../components/Page'
import SEO from '../components/SEO'
import Schema from '../components/Schema'
import SubNavigation from '../components/SubNavigation'
import LeadSpot from '../components/primary/LeadSpot'
import LeadStreamer from '../components/primary/LeadStreamer'
import BenefitsToYou from '../components/primary/BenefitsToYou'
import ExpandableImage from '../components/ExpandableImage'
import DemoVideo from '../components/primary/DemoVideo'
import PriceRange from '../components/primary/PriceRange'
import CompanyTabs from '../components/primary/CompanyTabs'
import TestimonialHalf from '../components/TestimonialsHalf'
import TutorialReel from '../components/primary/TutorialReel'
import CallToAction from '../components/primary/CallToAction'
import NewsArticleLink from '../components/articles/Listings/NewsArticleLink'
import EditorialArticleLink from '../components/articles/Listings/EditorialArticleLink'
import Newsletter from '../components/Newsletter'

import { isEven } from '../utils/num'

const ManufacturersPage = () => {
  const {
    erudusStats,
    markdownRemark,
    allDatoCmsTestimonial,
    newsArticles,
    editorialArticles,
  } = useStaticQuery(graphql`
    query ManufacturersPageQuery {
      erudusStats {
        manufacturerCount
      }
      markdownRemark(frontmatter: { path: { eq: "/manufacturers" } }) {
        frontmatter {
          title
          description
          path
          ...SubNavigationFragment
          lead {
            ...LeadSpotFragment
            ...LeadStreamerFragment
          }
          section3 {
            title
            text
            image {
              modal: childImageSharp {
                ...SharpImageModalFragment
              }
              childImageSharp {
                fixed(width: 550, quality: 90) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
          ...BenefitsToYouFragment
          benefitsCustomers {
            title
            blurbs {
              title
              text
              icon
            }
          }
          ...DemoVideoFragment
          ...CompanyTabsFragment
          ...TutorialReelFragment
          ...CallToActionFragment
        }
      }
      allDatoCmsTestimonial(
        filter: { categories: { elemMatch: { title: { eq: "Manufacturer" } } } }
        limit: 2
      ) {
        edges {
          node {
            ...TestimonialsFragment
          }
        }
      }
      newsArticles: allDatoCmsNewsArticle(
        filter: { tags: { elemMatch: { title: { in: "Manufacturers" } } } }
        sort: { fields: displayDate, order: DESC }
        limit: 3
      ) {
        edges {
          node {
            __typename
            title
            slug
            relPath
            category
            categoryLink
            pubDateTime
            heroImage {
              url
              alt
              title
              customData
            }
            thumbnailImage {
              url
              alt
              title
              customData
            }
          }
        }
      }
      editorialArticles: allDatoCmsEditorialArticle(
        filter: { tags: { elemMatch: { title: { in: "Manufacturers" } } } }
        sort: { fields: displayDate, order: DESC }
        limit: 3
      ) {
        edges {
          node {
            __typename
            title
            slug
            relPath
            category
            categoryLink
            pubDateTime
            heroImage {
              url
              alt
              title
              customData
            }
          }
        }
      }
    }
  `)

  const pageData = markdownRemark.frontmatter
  const testimonials = allDatoCmsTestimonial.edges
  const allArticles = _orderBy(
    [...newsArticles.edges, ...editorialArticles.edges],
    'node.pubDateTime',
    'desc'
  )
  const latestArticles = _take(allArticles, 3)

  return (
    <Page className="p-forManufacturers has-fingerPrints">
      <SEO title={pageData.title} description={pageData.description} />
      <Schema
        breadcrumbs={[
          {
            name: pageData.title,
            uri: pageData.path,
          },
        ]}
      />

      <SubNavigation subNav={pageData.subNav} />

      <LeadSpot
        title={pageData.lead.spot.title}
        header={pageData.lead.spot.header}
        text={pageData.lead.spot.text}
        image={pageData.lead.spot.image}
      />

      <LeadStreamer
        id={pageData.lead.streamer.id}
        tag={pageData.lead.streamer.tag}
        title={pageData.lead.streamer.title}
        text={pageData.lead.streamer.text}
        image={pageData.lead.streamer.image}
        imageWidth={pageData.lead.streamer.imageWidth}
      />

      <section className="c-section c-section--postStreamer">
        <div className="c-section__outer container">
          <div className="c-section__inner">
            <div className="c-postSteamer text-left">
              {pageData.section3.map((blurb, i) => (
                <div key={`section3-${i}`} className="row">
                  <div
                    className={`col-12 col-md-6 mb-5 ${
                      isEven(i) ? 'order-md-2' : ''
                    } d-flex flex-column justify-content-center`}
                  >
                    <h2 className="mb-4">{blurb.title}</h2>
                    <div className="c-postStreamer__text">
                      <p>{blurb.text}</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-5 px-5 text-center">
                    <ExpandableImage
                      imageData={blurb.image.modal}
                      bottomRight={!isEven(i)}
                    >
                      <picture>
                        <source
                          type="image/webp"
                          srcSet={blurb.image.childImageSharp.fixed.srcSetWebp}
                        />
                        <img
                          srcSet={blurb.image.childImageSharp.fixed.srcSet}
                          src={blurb.image.childImageSharp.fixed.src}
                          alt={blurb.title}
                          title={blurb.title}
                          width={blurb.image.childImageSharp.fixed.width}
                          height={blurb.image.childImageSharp.fixed.height}
                          loading="lazy"
                          className="c-postStreamer__image img-fluid px-3"
                        />
                      </picture>
                    </ExpandableImage>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <BenefitsToYou
        upper={pageData.benefitsYou.blurbsOne}
        lower={pageData.benefitsYou.blurbsTwo}
      />

      <section
        id="benefits-for-your-customers"
        className="c-section c-section--forBenefits"
      >
        <div className="c-section__outer container">
          <div className="c-section__tag">Benefits to your customers</div>
          <div className="c-section__inner">
            <div className="c-forCustBenefits row">
              <div className="c-forCustBenefits__header col-12">
                <h2>{pageData.benefitsCustomers.title}</h2>
              </div>
              {pageData.benefitsCustomers.blurbs.map((blurb) => (
                <div key={blurb.title} className="col-12 col-md-4">
                  <img
                    src={`/images/icons/${blurb.icon}.png`}
                    srcSet={`/images/icons/${blurb.icon}.svg`}
                    alt={`${blurb.title} icon`}
                    className="c-forCustBenefits__icon img-fluid"
                  />
                  <p className="c-forCustBenefits__title">{blurb.title}</p>
                  <p className="c-forCustBenefits__text">{blurb.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <DemoVideo
        header={pageData.demoVideo.header}
        text={`${erudusStats.manufacturerCount} ${pageData.demoVideo.text}`}
        buttonText={pageData.demoVideo.buttonText}
        buttonLink={pageData.demoVideo.buttonLink}
        videoUrl={pageData.demoVideo.videoUrl}
      />

      <PriceRange />

      <CompanyTabs companiesData={pageData.companies} />

      <TestimonialHalf testimonials={testimonials} />

      <TutorialReel
        header={pageData.subVideos.header}
        buttonText={pageData.subVideos.buttonText}
        buttonLink={pageData.subVideos.buttonLink}
        filter={pageData.subVideos.filter}
        videos={pageData.subVideos.videos}
      />

      <CallToAction ctaData={pageData.signUpCta} />

      <section id="latest-updates">
        <div className="container">
          <div className="row py-5 c-newsItemWithHeader">
            {latestArticles.map(({ node }, i) => (
              <div
                key={node.slug}
                className={cn('col-6 col-lg-4', {
                  'd-none d-lg-block': i === 2,
                })}
              >
                {node.__typename === 'DatoCmsNewsArticle' && (
                  <NewsArticleLink article={node} />
                )}
                {node.__typename === 'DatoCmsEditorialArticle' && (
                  <EditorialArticleLink article={node} />
                )}
              </div>
            ))}
          </div>
        </div>
      </section>

      <Newsletter />
    </Page>
  )
}

export default ManufacturersPage
